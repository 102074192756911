import axios, { AxiosRequestConfig, AxiosInterceptorManager } from 'axios';

import { API_URL } from 'config/env';
import storage from 'utils/storage';

const authRequestInterceptor: (
  config: AxiosRequestConfig,
) => AxiosRequestConfig = (config) => {
  const clonedConfig = { ...config };
  clonedConfig.headers = clonedConfig.headers ?? {};
  const token = storage.getToken();
  if (token) {
    clonedConfig.headers.Authorization = `Bearer ${token}`;
  }
  return clonedConfig;
};

export const customAxios = axios.create({
  baseURL: API_URL,
});

const interceptors = customAxios.interceptors
  .request as AxiosInterceptorManager<AxiosRequestConfig>;

interceptors.use(authRequestInterceptor);
